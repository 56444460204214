import capitalize from 'lodash/capitalize'

export const DEFAULT_SEPARATOR = '-'

export const ENUM_DEFAULT_SEPARATOR = '_'

export const jsonSafeParse = (value: string) => {
  try {
    return JSON.parse(value)
  } catch {
    return value
  }
}

export const isJson = (str: string) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }

  return true
}

export const labelFormatter = (
  label: string,
  separator = DEFAULT_SEPARATOR
): string => {
  if (label) {
    const labelSegments = label.split(separator)

    if (labelSegments.length) {
      return labelSegments.reduce(
        (formatName, name) => `${formatName} ${capitalize(name)}`,
        ''
      )
    }
  }

  return ''
}

export const checkIfValidUUID = (str: string) => {
  const uuidRegexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

  return uuidRegexExp.test(str)
}

export const convertEnumLabelToText = (label: string): string => {
  const UpperCaseRegx = /([A-Z])/g
  const EmptyStringBetweenUpperCaseRegx = /([A-Z])\s(?=[A-Z])/g

  if (!label) {
    return ''
  }

  let dataTypeLabel = label?.replace(UpperCaseRegx, ' $1')?.trim()

  dataTypeLabel = dataTypeLabel.replace(EmptyStringBetweenUpperCaseRegx, '$1')

  return dataTypeLabel
}

export const convertEnumToOptions = (enumKeys: string[]) =>
  (enumKeys || []).map(data => ({
    value: data,
    label: convertEnumLabelToText(data),
  }))

export const createStringPatternForRegex = (str: string) =>
  str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // escape special characters
